// extracted by mini-css-extract-plugin
export const categoryRow = "workout-category-row-module--category-row--h0TDQ";
export const categoryIconContainer = "workout-category-row-module--category-icon-container--2MlEe";
export const categoryWrapper = "workout-category-row-module--category-wrapper--jBs9K";
export const scrollArrow = "workout-category-row-module--scroll-arrow--2O9S3";
export const scrollArrowRight = "workout-category-row-module--scroll-arrow-right--14MzN";
export const scrollArrowLeft = "workout-category-row-module--scroll-arrow-left--3xvt2";
export const hidden = "workout-category-row-module--hidden--1m2cT";
export const categoryButton = "workout-category-row-module--category-button--18Sau";
export const categoryContainer = "workout-category-row-module--category-container--2vpLN";
export const categoryContent = "workout-category-row-module--category-content--3vJQM";
export const flexboxCentering = "workout-category-row-module--flexbox-centering--mK5Ce";
export const viewportSizing = "workout-category-row-module--viewport-sizing--vDsYb";
export const link = "workout-category-row-module--link--34LDh";