import React, { useCallback, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import Colors from 'src/utils/colors';
import { Link } from 'gatsby';
import { Icon, FontOrSvgIcon } from 'src/components/shared';
import * as Styles from 'src/styles/workout-category-row.module.scss';
import { throttle } from 'lodash';
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { useSelector } from 'react-redux';
import { IWorkoutCategoryState } from '@pvolve/sdk/src/redux/selectors';

interface WorkoutCategoryRowProps {
    categoryId: string;
}

interface WorkoutCategoryProps {
    category: IWorkoutCategoryState;
    active: boolean;
    hoverSlug: string;
    setHoverSlug: (slug: string) => void;
}

const WorkoutCategory = ({ category, active, setHoverSlug, hoverSlug }: WorkoutCategoryProps) => {
    const { name, slug, svgIcon } = category;

    const fontColor = {
        active: Colors.black,
        inactive: Colors.white,
    };
    const backgroundColor = {
        active: Colors.white,
        inactive: Colors.black,
    };

    const updateSVGColors = (SVGCode: string = '', color: string) => {
        const FILL_REGEX: RegExp = /fill="([^"]+)"/g;
        const STROKE_REGEX: RegExp = /stroke="([^"]+)"/g;

        return SVGCode.replace(FILL_REGEX, `fill="${color}"`).replace(
            STROKE_REGEX,
            `stroke="${color}"`
        );
    };

    const hover = hoverSlug === slug;
    const activeOrHover = active || hover;
    const activeStyles = activeOrHover
        ? {
            backgroundColor: backgroundColor.active,
            color: fontColor.active,
        }
        : {};

    const itemClasses = classnames(Styles.categoryButton, 'accent lower');

    const onMouseEnter = useCallback(() => {
        setHoverSlug(slug);
    }, [setHoverSlug, slug]);

    const onMouseLeave = useCallback(() => setHoverSlug(''), []);

    return active ? (
        <div style={activeStyles} className={itemClasses}>
            <FontOrSvgIcon
                color={active ? fontColor.active : fontColor.inactive}
                icon={`pv-${slug}`}
                svg={updateSVGColors(svgIcon, active ? fontColor.active : fontColor.inactive)}
            />
            {name}
        </div>
    ) : (
        <Link
            style={activeStyles}
            className={classnames(Styles.categoryButton, 'accent lower')}
            to={`/workouts/categories/${slug}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <FontOrSvgIcon
                color={hover ? fontColor.active : fontColor.inactive}
                icon={`pv-${slug}`}
                svg={updateSVGColors(svgIcon, hover ? fontColor.active : fontColor.inactive)}
            />
            {name}
        </Link>
    );
};

const WorkoutCategoryRow = ({ categoryId }: WorkoutCategoryRowProps) => {
    const [hoverSlug, setHoverSlug] = useState<string>('');
    const workoutCategories = useSelector(
        PvolveSelectors.workoutCategories.workoutCategoriesResolve
    );

    const categoriesFiltered = [
        ...workoutCategories?.targetedCategories,
        ...workoutCategories?.defaultCategories,
    ];

    const Category = (category: IWorkoutCategoryState, index: number) => (
        <WorkoutCategory
            key={`workout-category-${index}`}
            category={category}
            setHoverSlug={setHoverSlug}
            hoverSlug={hoverSlug}
            active={category.id === categoryId}
        />
    );

    const categoryTabs = useRef<HTMLDivElement>(null);
    const categoryIcons = useRef<HTMLDivElement>(null);
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    const scrollCategories = useCallback(
        (direction) => () => {
            if (categoryTabs.current) {
                const catTabs = categoryTabs.current;
                catTabs.style.scrollBehavior = 'smooth';

                const step = isMobile ? 152 : catTabs.clientWidth;

                const isNearingRowEnd =
                    step + catTabs.scrollLeft + catTabs.clientWidth > catTabs.scrollWidth - step;

                const isNearingRowStart = catTabs.scrollLeft - step < step;

                if (direction === 'right') {
                    if (isNearingRowEnd) {
                        catTabs.scrollLeft = catTabs.scrollWidth;
                    } else {
                        catTabs.scrollLeft += step;
                    }
                } else {
                    if (isNearingRowStart) {
                        catTabs.scrollLeft = 0;
                    } else {
                        catTabs.scrollLeft -= step;
                    }
                }
            }
        },
        [categoryTabs]
    );

    const updateRightArrowClass = () => {
        const scrollPosition = Math.round(
            categoryTabs?.current?.scrollLeft + categoryTabs?.current?.clientWidth
        );
        const hasReachedEnd = categoryTabs?.current?.scrollWidth <= scrollPosition;
        const hideArrow = categoryTabs?.current && categoryIcons?.current && hasReachedEnd;
        return classnames(Styles.scrollArrow, Styles.scrollArrowRight, {
            [Styles.hidden]: hideArrow,
        });
    };

    const updateLeftArrowClass = () =>
        classnames(Styles.scrollArrow, Styles.scrollArrowLeft, {
            [Styles.hidden]: !categoryTabs?.current || categoryTabs?.current?.scrollLeft < 100,
        });

    const [scrollArrowRightClassnames, setScrollArrowRightClassnames] = useState<string>(
        updateRightArrowClass()
    );
    const [scrollArrowLeftClassnames, setScrollArrowLeftClassnames] = useState<string>(
        updateLeftArrowClass()
    );

    const updateScrollClasses = useCallback(
        throttle(() => {
            setScrollArrowRightClassnames(updateRightArrowClass());
            setScrollArrowLeftClassnames(updateLeftArrowClass());
        }, 200),
        []
    );

    window.addEventListener('resize', updateScrollClasses);

    useEffect(() => {
        setScrollArrowRightClassnames(updateRightArrowClass());
        setScrollArrowLeftClassnames(updateLeftArrowClass());
    }, []);

    if (!categoriesFiltered) {
        return null;
    }

    return (
        <div className={Styles.categoryWrapper}>
            <button onClick={scrollCategories('left')} className={scrollArrowLeftClassnames}>
                <Icon name="pv-arrow-left" size={16} />
            </button>

            <div ref={categoryTabs} className={Styles.categoryRow} onScroll={updateScrollClasses}>
                <div ref={categoryIcons} className={Styles.categoryIconContainer}>
                    {categoriesFiltered.map(Category)}
                </div>
            </div>

            <button onClick={scrollCategories('right')} className={scrollArrowRightClassnames}>
                <Icon name="pv-arrow-right" size={16} />
            </button>
        </div>
    );
};

export default WorkoutCategoryRow;
