import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import SEO from 'src/components/SEO';
import WorkoutCategoryRow from 'src/components/workouts/WorkoutCategoryRow';
import WorkoutCard from 'src/components/shared/WorkoutCard';
import WorkoutFilters from 'src/components/workout-library/WorkoutFilters';

import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { CardGridWrapper, Icon, FloatingTryForFree, LinkWithArrow } from 'src/components/shared';
import { sortWorkoutsByDate } from '@pvolve/sdk/src/app/utils';

import * as Styles from 'src/styles/workout-category-page.module.scss';
import classNames from 'classnames';

const mapStateToProps = (state: IState) => ({
    completeFilteredResults: PvolveSelectors.content.completeFilteredResults(state),
    workoutFilter: PvolveSelectors.content.workoutFilter(state),
    entitled: Selectors.auth.entitled(state),
});

const connector = connect(mapStateToProps);

const WorkoutCategoryPageTemplate = ({
    completeFilteredResults,
    data,
    workoutFilter,
    entitled,
}) => {
    const {
        contentfulWorkoutCategory: {
            contentful_id: categoryId,
            name: categoryName,
            workouts: categoryWorkouts,
            description: categoryDescription,
            slug,
        },
    } = data;

    const {
        results,
        resultsWithAdditionalEquipment,
        resultsWithEquipmentOnly,
        wasFilteredByEquipment,
    } = completeFilteredResults;

    const sortedWorkouts = sortWorkoutsByDate(categoryWorkouts || [], 'date');

    const workoutCards = (workout, index: number) => {
        return <WorkoutCard key={`workout-card-${index}`} workout={workout} />;
    };

    const filterCategoryWorkouts = (workouts, categoryWorkouts) => {
        const filterWorkouts = [];

        if (!!workouts && workouts.length > 0) {
            workouts.map((workout) => {
                const filterItem = categoryWorkouts.find(
                    (categoryWorkout) => categoryWorkout.slug === workout.item.fields.slug
                );
                if (!!filterItem) {
                    filterWorkouts.push(filterItem);
                }
            });
        }
        const sortedFilterWorkouts = sortWorkoutsByDate(filterWorkouts, 'date');

        return sortedFilterWorkouts;
    };

    const filterCount = Object.values(workoutFilter).length;

    const filterWorkouts = filterCategoryWorkouts(results, categoryWorkouts);
    const filterEquipmentWorkouts = filterCategoryWorkouts(
        resultsWithEquipmentOnly,
        categoryWorkouts
    );
    const filterAdditionalWorkouts = filterCategoryWorkouts(
        resultsWithAdditionalEquipment,
        categoryWorkouts
    );

    const isDesktop = useBreakpoints(Breakpoints.lg, Directions.up);
    const workoutsList =
        filterWorkouts.length > 0
            ? filterWorkouts
            : filterCount > 0
                ? filterWorkouts
                : sortedWorkouts;

    const numWorkouts = workoutsList?.length || 0;

    const [drawerVisible, setDrawerVisible] = useState(false);
    const toggleDrawer = (direction: boolean) => () => {
        setDrawerVisible(direction);
    };
    /**
     * In this template we can access Contentful data one of two ways
     *  - Use the slug to make SDK calls to get data in the client at runtime
     *  - OR, use a gatsby page query to directly query Contenful during build time
     */
    return (
        <>
            <SEO title={`WorkoutCategory: ${categoryName}`} />
            <LinkWithArrow
                className={Styles.backToWorkouts}
                back
                to="/workouts"
                label="Back to workouts"
            />
            <div>
                <Grid.Row>
                    <Grid.Column>
                        <WorkoutCategoryRow categoryId={categoryId} />
                        <div className={classNames('margin-top--large', Styles.seriesHeader)}>
                            <Grid.Row
                                className={
                                    isDesktop
                                        ? Styles.headerContainer
                                        : 'justifyContent-space-between'
                                }
                            >
                                <Grid.Row
                                    className={classNames(
                                        'padding--0',
                                        'margin-bottom--small',
                                        isDesktop ? 'display-row' : 'display-column',
                                        'justifyContent-space-between'
                                    )}
                                    verticalAlign="middle"
                                >
                                    <Grid.Column className="padding--0" width={10} floated="left">
                                        <h2 className="bold upper">{categoryName}</h2>
                                    </Grid.Column>
                                    {numWorkouts && (
                                        <Grid.Column
                                            className={classNames(
                                                'padding--0',
                                                isDesktop && 'text-align--right'
                                            )}
                                            width={6}
                                            floated={isDesktop ? 'right' : 'left'}
                                        >
                                            <span>
                                                {numWorkouts} workout
                                                {numWorkouts > 1 ? 's' : ''}
                                            </span>
                                        </Grid.Column>
                                    )}
                                </Grid.Row>
                                {!isDesktop && (
                                    <div className={Styles.filterHeaderMobile}>
                                        <Icon
                                            name="pv-filters-horizontal-outline"
                                            size={34}
                                            onClick={() => setDrawerVisible(!drawerVisible)}
                                        />
                                    </div>
                                )}
                            </Grid.Row>
                            {categoryDescription && categoryDescription.description && (
                                <Grid.Row className="padding-bottom--large">
                                    <Grid.Column className="padding--0">
                                        <p className="big">{categoryDescription.description}</p>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <WorkoutFilters
                                count={workoutsList?.length}
                                category={slug}
                                isWhiteStyle={true}
                                isHiddenCounter={true}
                                drawerVisible={drawerVisible}
                                drawerOn={toggleDrawer(true)}
                                drawerOff={toggleDrawer(false)}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="padding--0">
                    <Grid.Column>
                        <Grid className={Styles.workoutsResultsContainer}>
                            {!wasFilteredByEquipment ? (
                                <Grid.Row className="padding--0 margin-top--large">
                                    {numWorkouts ? (
                                        <CardGridWrapper>
                                            {workoutsList.map(workoutCards)}
                                        </CardGridWrapper>
                                    ) : (
                                        <p className="margin-bottom--large">No workouts found</p>
                                    )}
                                </Grid.Row>
                            ) : numWorkouts ? (
                                <>
                                    {!!filterEquipmentWorkouts &&
                                        filterEquipmentWorkouts.length > 0 && (
                                        <Grid.Row
                                            className={classNames(
                                                'padding--0 margin-top--large',
                                                filterAdditionalWorkouts.length > 0 &&
                                                        Styles.equipmentSectionNoPadding
                                            )}
                                        >
                                            <div className={Styles.workoutSectionTitle}>
                                                <p className="h3 upper bold margin-bottom--0">
                                                        Features selected equipment only
                                                </p>
                                                <p>{`${filterEquipmentWorkouts.length} workouts`}</p>
                                            </div>
                                            <CardGridWrapper>
                                                {filterEquipmentWorkouts.map(workoutCards)}
                                            </CardGridWrapper>
                                        </Grid.Row>
                                    )}

                                    {!!filterAdditionalWorkouts &&
                                        filterAdditionalWorkouts.length > 0 && (
                                        <Grid.Row
                                            className={classNames(
                                                'padding--0',
                                                filterEquipmentWorkouts.length > 0
                                                    ? 'margin-top--xlarge'
                                                    : 'margin-top--large'
                                            )}
                                        >
                                            <div className={Styles.workoutSectionTitle}>
                                                <p className="h3 upper bold margin-bottom--0">
                                                        FEATURES selected & ADDITIONAL equipment
                                                </p>
                                                <p>{`${filterAdditionalWorkouts.length} workouts`}</p>
                                            </div>
                                            <CardGridWrapper>
                                                {filterAdditionalWorkouts.map(workoutCards)}
                                            </CardGridWrapper>
                                        </Grid.Row>
                                    )}
                                </>
                            ) : (
                                <p className="margin-bottom--large">No workouts found</p>
                            )}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </div>
            {!entitled && <FloatingTryForFree />}
        </>
    );
};

export const query = graphql`
    query WorkoutCategoryPageQuery($contentful_id: String!) {
        contentfulWorkoutCategory(contentful_id: { eq: $contentful_id }) {
            contentful_id
            name
            slug
            workouts {
                ...ContentfulWorkout_Card
            }
            description {
                description
            }
        }
    }
`;

export default connector(WorkoutCategoryPageTemplate);
